<template>
    <div>
        <div class="e-breadcrumb">假期管理</div>
        <div class="order-table-wrapper">
            <div class="e-card">
                <div class="e-card-body" style="padding-top:35px">
                    <div class="e-handle-box" style="margin-bottom: 50px">
                        <div class="handle-box">
                            <span class="v-form-label">假期名称:</span>
                            <el-input placeholder="假期名称" size="small" style="width:265px" v-model="vacName" clearable
                                      class="handle-input mr10"></el-input>
                            <span class="v-form-label">假期类型:</span>
                            <el-select class="handle-input mr10" size="small" style="width:265px" v-model="vacType"
                                       filterable clearable placeholder="请选择假期类型">
                                <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                ></el-option>
                            </el-select>
                            <div style="margin-left:  69px">
                                <el-row type="flex" justify="end">
                                    <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
                                    </el-button>
                                    <el-button size="small" class="e-button" type="success" @click="addEvent()">新增
                                    </el-button>
                                </el-row>
                            </div>
                        </div>
                    </div>
                    <br/> <br/>

                    <div class="e-table-wrapper">
                        <table cellspacing="0" cellpadding="0" border="0" width="100%" style="text-align: center">
                            <thead>
                            <tr>
                                <th class="th-l">假期名称</th>
                                <th class="th-l">假期类型</th>
                                <th class="th-l">操作</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(row,index) in list" :key="row.id">
                                <td class="td-l">{{row.vacName}}</td>
                                <td class="td-l">
                                 <span v-if="row.vacType==1">正休</span>
                                 <span v-if="row.vacType==2">奖励</span>
                                 <span v-if="row.vacType==3">请假</span>
                                </td>
                                <td class="td-l" style="width: 188px">
                                    <el-button type="text" icon="el-icon-edit" @click="editEvent(index)">编辑
                                    </el-button>
                                    <el-button type="text" icon="el-icon-delete" class="red"
                                               @click="removeEvent(index)">删除
                                    </el-button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="pagination">
                            <el-pagination
                                    background
                                    @current-change="handleCurrentChange"
                                    :pager-count="7"
                                    layout="total, prev, pager, next,jumper"
                                    :total="pagination.count"
                                    :page-count="pagination.pages"
                                    :current-page="pagination.pageNumber"
                            ></el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Insert v-if="addVisible" :addVisible="addVisible" @closeInsert="closeInsert" @addSuccess="addSuccess"/>
        <Edit v-if="editVisible" :editVisible="editVisible" @closeEdit="closeEdit" @editSuccess="editSuccess" :editItem="editItem" :item="integral" />

        <!-- 删除提示框 -->
        <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
            <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delVacation">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import Insert from './insert'
    import Edit from './edit'

    export default {
        data() {
            return {
                vacName:'',
                vacType:'',

                list: [],
                pagination: {
                    count: 0,
                    pageNumber: 0,
                    pageSize: 0,
                    pages: 1
                },

                addVisible: false,
                editVisible: false,
                delVisible: false,
                selItem: {},
                editItem: {},
                integral:{},
                options: [{
                    value: '1',
                    label: '正休'
                }, {
                    value: '2',
                    label: '奖励'
                }, {
                  value: '3',
                  label: '请假'
                }
                ]
            }
        },
        async created() {
            this.searchEvent();
        },
        methods: {

            handleCurrentChange(page) {
                this.requestSearch(page)
            },
            searchEvent() {
                this.requestSearch()
            },
            async requestSearch(page) {
                let currentPage = page || 1;
                let data = {
                    vacName: this.vacName,
                    vacType: this.vacType,
                    size: this.size == '' ? '10' : this.size,
                    currentPage
                }
                let dataSet = await this.$get("/admin/getVacationForPage", data)
                let res = dataSet.dataSet;
                let list = res.list
                this.list = list;
                this.pagination = {
                    count: res.count,
                    pageNumber: res.pageNumber,
                    pageSize: res.pageSize,
                    pages: res.pages
                }
            },

            addEvent() {
                this.addVisible = true;
            },

           async editEvent(index) {
             this.editItem = this.list[index]
             // let  vacationId =this.editItem.id
             // let res = await this.$get("/admin/getIntegralList", {vacationId:vacationId})
             // this.integral =res.item
             this.editVisible = true;
            },


            async removeEvent(index) {
                this.selItem = this.list[index]
                this.delVisible = true;
            },

            //删除
            async delVacation() {
                let data = {
                    id: this.selItem.id
                };
                let res = await this.$post("/admin/removeVacation", data)
                if (res.code == 200) {
                    this.searchEvent();
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'success'
                    });
                    this.delVisible = false;
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },

            closeInsert() {
                this.addVisible = false;
            },

            closeEdit() {
                this.editVisible = false;
            },

            addSuccess() {
                this.closeInsert();
                this.searchEvent();
            },

            editSuccess() {
                this.closeEdit();
                this.searchEvent();
            }
        },
        components: {
            Insert,
            Edit
        }
    }

</script>

<style scoped>
    .th-l {
        text-align: center;
        padding-right: 10px;
    }

    .td-l {
        padding: 10px 12px;
        display: table-cell;
        text-align: center;
        vertical-align: middle;
    }
</style>
