<template>
    <el-dialog v-if="addVisible" title="新增" width="490px" :visible.sync="addVisible" :before-close="closeEvent">
        <el-form ref="form" label-width="120px" style="margin-top: -10px">
          <el-form-item label="假期类型:" required>
            <el-select size="small" v-model="vacType" placeholder="请选择" style="width: 268px;">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
            <el-form-item label="假期名称:" required>
                <el-input size="small" v-model="vacName" maxlength="64" show-word-limit style="width: 268px;"></el-input>
            </el-form-item>
<!--            <el-form-item label="请假积分设置" required style="width: 435px;">-->
<!--            </el-form-item>-->
<!--         <div style="margin-left: 25px;margin-top: -20px">-->
<!--           <div class="demo-input-suffix">-->
<!--             <span class="sp">30分钟记:</span>-->
<!--             <el-input-->
<!--               v-model="item.one"-->
<!--               style="width: 68px"-->
<!--               size="small"-->
<!--             >-->
<!--             </el-input>-->
<!--             积分-->
<!--             <span class="sp">1小时记:</span>-->
<!--             <el-input-->
<!--               v-model="item.two"-->
<!--               style="width: 68px"-->
<!--               size="small"-->
<!--             >-->
<!--             </el-input>-->
<!--             积分-->
<!--           </div>-->
<!--           <div class="demo-input-suffix">-->
<!--             <span class="sp">3小时记:</span>-->
<!--             <el-input-->
<!--               v-model="item.three"-->
<!--               style="width: 68px"-->
<!--               size="small"-->
<!--             >-->
<!--             </el-input>-->
<!--             积分-->
<!--             <span class="sp">6小时记:</span>-->
<!--             <el-input-->
<!--               v-model="item.four"-->
<!--               style="width: 68px"-->
<!--               size="small"-->
<!--             >-->
<!--             </el-input>-->
<!--             积分-->
<!--           </div>-->
<!--           <div class="demo-input-suffix">-->
<!--             <span class="sp">1天记:</span>-->
<!--             <el-input-->
<!--               v-model="item.five"-->
<!--               style="width: 68px"-->
<!--               size="small"-->
<!--             >-->
<!--             </el-input>-->
<!--             积分-->
<!--             <span class="sp">2天记:</span>-->
<!--             <el-input-->
<!--               v-model="item.six"-->
<!--               style="width: 68px"-->
<!--               size="small"-->
<!--             >-->
<!--             </el-input>-->
<!--             积分-->
<!--           </div>-->
<!--           <div class="demo-input-suffix">-->
<!--             <span class="sp">3天记:</span>-->
<!--             <el-input-->
<!--               v-model="item.seven"-->
<!--               style="width: 68px"-->
<!--               size="small"-->
<!--             >-->
<!--             </el-input>-->
<!--             积分-->
<!--             <span class="sp">4天记:</span>-->
<!--             <el-input-->
<!--               v-model="item.eight"-->
<!--               style="width: 68px"-->
<!--               size="small"-->
<!--             >-->
<!--             </el-input>-->
<!--             积分-->
<!--           </div>-->
<!--         </div>-->
        </el-form>
        <div class="card-footer" style="width: 398px;">
            <el-button size="small" @click="closeEvent">取 消</el-button>
            <el-button size="small" type="primary" @click="saveVacation">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>

    export default {
        data() {
            return {
              vacName:'',
              vacType:'',
              options: [{
                value: '1',
                label: '正休'
              }, {
                value: '2',
                label: '奖励'
              }, {
                value: '3',
                label: '请假'
              }],
              item:{
                one:'',
                two:'',
                three:'',
                four:'',
                five:'',
                six:'',
                seven:'',
                eight:'',
              },
            }
        },
        props: {
            addVisible: Boolean
        },
        async created() {

        },
        methods: {

            closeEvent() {
                this.$emit('closeInsert')
            },

            async saveVacation() {
                let data = {
                    vacName: this.vacName,
                    vacType: this.vacType,
                    mapStr: JSON.stringify(this.item),
                };
                if (!this.vacName) {
                    this.$message.error('请输入名称!');
                    return
                }
                if (!this.vacType) {
                    this.$message.error("请选择假期类型!");
                    return
                }
                // if (!this.item.one) {
                //   this.$message.error("请填写相应积分设置!");
                //   return
                // }
                // if (!this.item.two) {
                //   this.$message.error("请填写相应积分设置!");
                //   return
                // }
                // if (!this.item.three) {
                //   this.$message.error("请填写相应积分设置!");
                //   return
                // }
                // if (!this.item.four) {
                //   this.$message.error("请填写相应积分设置!");
                //   return
                // }
                // if (!this.item.five) {
                //   this.$message.error("请填写相应积分设置!");
                //   return
                // }
                // if (!this.item.six) {
                //   this.$message.error("请填写相应积分设置!");
                //   return
                // }
                // if (!this.item.seven) {
                //   this.$message.error("请填写相应积分设置!");
                //   return
                // }
                // if (!this.item.eight) {
                //   this.$message.error("请填写相应积分设置!");
                //   return
                // }

                let res = await this.$post("/admin/saveVacation", data);
                if (res.code == 200) {
                    this.$message.success(res.message);
                    this.$emit('addSuccess')
                }else {
                  this.$message.error(res.message);
                }
            }
        }
    }
</script>
<style>
    .e-file-upload .e-upload--text{
        width: 137px;
        height: 137px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center
    }
    .e-file-upload .e-upload--text .e-upload--icon{
        margin-top: 40px;
        font-size: 25px;
        color: rgb(0,133,215);
    }
  .demo-input-suffix{
    margin-bottom: 6px;
  }
  .demo-input-suffix input{
    border-radius: 5px;
  }
  .sp {
    margin-left: 8px;
    width: 78px;
    display: inline-block;
    text-align: right;
    padding: 0 8px 0 0;
  }
</style>
